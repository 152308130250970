<template>
  <div class="container">
    <Placeholder v-if="props.type === 'single'" />
    <h1 class="title" v-if="props.type === 'single'">加入我们</h1>
    <h1 class="title" v-else>加入生态</h1>
    <div class="colomn">
      <div style="flex: 1; position: relative">
        <label
          for="name"
          style="
            position: absolute;
            margin-top: 20px;
            margin-left: 8px;
            color: red;
          "
          >*</label
        >
        <input
          id="name"
          class="form"
          type="text"
          placeholder=" 姓名"
          required
          v-model="formState.name"
        />
      </div>
      <div style="flex: 1; position: relative">
        <label
          for="name"
          style="
            position: absolute;
            margin-top: 20px;
            margin-left: 8px;
            color: red;
          "
          >*</label
        >
        <input
          class="form"
          type="text"
          placeholder=" 手机/微信"
          required
          v-model="formState.phoneNumber"
        />
      </div>
      <div style="flex: 1; position: relative">
        <label
          for="name"
          style="
            position: absolute;
            margin-top: 20px;
            margin-left: 8px;
            color: red;
          "
          >*</label
        >
        <input
          class="form"
          type="text"
          placeholder=" 邮箱"
          required
          v-model="formState.email"
        />
      </div>
    </div>
    <input
      class="form"
      type="text"
      placeholder="公司名称"
      v-model="formState.projectName"
    />
    <textarea
      class="form"
      rows="3"
      placeholder="公司简介"
      v-model="formState.projectIntro"
    ></textarea>
    <input
      type="text"
      v-model="formState.hiddenField"
      style="display: none"
      autocomplete="off"
    />
    <button class="submit" type="submit" @click="submit">
      <span>提&nbsp;&nbsp;&nbsp;&nbsp;交</span>
    </button>
    <Placeholder v-if="props.type === 'single'" />
  </div>
</template>

<script setup>
import { submitForm } from "@/api/api";
import Placeholder from "@/components/Placeholder.vue";
import { message } from "ant-design-vue";
import { defineProps, ref } from "vue";
const props = defineProps(["type"]);

const formState = ref({});
const submit = () => {
  if (formState.value.hiddenField) {
    message.warn("检测到恶意提交");
    return;
  }
  if (!formState.value.name) {
    message.warn("姓名不能为空");
    return;
  }
  if (!formState.value.phoneNumber) {
    message.warn("手机/微信不能为空");
    return;
  }
  if (!formState.value.email) {
    message.warn("邮箱不能为空");
    return;
  }
  if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formState.value.email)) {
    message.warn("请输入正确的邮箱格式");
    return;
  }
  submitForm(formState.value).then((res) => {
    if (res.code === 0) {
      message.success("提交成功");
    } else {
      message.error(res.msg);
    }
  });
};
</script>

<style scoped>
.container {
  background-color: #f8f9fa;
  padding: 20px 80px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.container .title {
  color: #0dcaf0;
  letter-spacing: 2px;
}

.colomn {
  display: flex;
  gap: 20px;
}

input[type="button"],
input[type="text"],
input[type="email"],
input[type="search"],
input[type="password"],
textarea,
input[type="submit"] {
  outline: none;
}

.submit {
  margin-left: auto;
}

.form {
  font-family: sans-serif;
  width: calc(100% - 25px);
  min-height: 46px;
  border-radius: 0;
  display: block;
  padding: 6px 12px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: black;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid #dee2e6;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.submit {
  width: 200px;
  padding: 12px 24px !important;
  position: relative;
  z-index: 9999;
  display: inline-block;
  font-size: 15px !important;
  line-height: normal;
  padding: 16px 30px;
  letter-spacing: 2px;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  color: #ffffff;
  background-color: #0dcaf0;
  border: none;
  z-index: 2;
  overflow: hidden;
  border-radius: 0;
}

.submit:before {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  display: block;
  content: "";
  width: 0;
  height: 0;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 12px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
}

@media screen and (max-width: 720px) {
  .colomn {
    flex-direction: column;
  }
  .submit {
    width: 120px;
  }
}
</style>
