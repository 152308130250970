<template>
  <div class="container" v-if="isVisable">
    <div v-for="item in content.importantData" :key="item.title" class="item">
      <div class="line-all">
        <div class="line">
          <span class="sm-dot" style="font-weight: bold"></span>{{ item.title }}
        </div>
      </div>

      <h1 class="data">
        <NumberAnimation
          :ref="item.title"
          :from="0"
          :to="item.data"
          :format="(value) => Number(value).toFixed(0)"
          :duration="2"
        />
        +
      </h1>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref} from "vue"
import content from "@/assets/home/content.json";
import NumberAnimation from "vue-number-animation";
const isVisable = ref(false)
onMounted(() => {
  window.addEventListener('scroll', windowScrollListener);
})
onUnmounted(() => {
  window.removeEventListener('scroll', windowScrollListener)
})
const windowScrollListener = () => {
  const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
  if (scrollTop >= 1000) {
    isVisable.value = true;
  }
  if (scrollTop < 1000) {
    isVisable.value = false;
  }
}
</script>

<style scoped>
.container {
  background-color: rgb(248, 249, 250);
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.container .item {
  flex: 1;
  border-right: 1px solid #dee2e6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 40px 0;
}

.container .item .sm-dot {
  width: 8px;
  height: 8px;
  background-color: #0dcaf0;
  margin-right: 10px;
  display: inline-block;
}

.container .item .line-all {
  min-width: 70%;
  max-width: 90%;
  font-family: "Syne", sans-serif;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.container .item .line {
  display: flex;
  align-items: center;
  justify-content: start;
}

.container .item .data {
  color: #0dcaf0;
  font-family: "Syne", sans-serif;
}

@media screen and (max-width: 720px) {
  .container {
    flex-direction: column;
  }
  .container .item {
    border-bottom: 1px solid #dee2e6;
  }
}
</style>
