<template>
  <div class="container">
    <div v-for="item in content.projects" :key="item.title" class="card">
      <div class="title">{{ item.title }}</div>
      <img :src="require('@/assets/home/' + item.img)" class="img" />
      <div class="item" v-for="(ii, index) in item.contents" :key="index">
        <div class="dot"></div>
        <span>{{ ii }}</span>
      </div>
      <button class="submit" type="submit" @click="showModal">
        <span>了解更多</span>
      </button>
      <!-- <div class="shape1"></div> -->
      <!-- <div class="shape2"></div> -->
    </div>
  </div>
  <a-modal v-model:open="open" title="联系我们" :width="800">
    <template #footer>
      <a-button key="back" @click="handleCancel">取消</a-button>
      <a-button key="submit" type="primary" @click="handleOk">提交</a-button>
    </template>
    <div style="display: flex; flex-direction: column; gap: 20px">
      <div class="colomn">
        <div style="flex: 1; position: relative">
          <label
            for="name"
            style="
              position: absolute;
              margin-top: 20px;
              margin-left: 8px;
              color: red;
            "
            >*</label
          >
          <input
            id="name"
            class="form"
            type="text"
            placeholder=" 姓名"
            required
            v-model="formState.name"
          />
        </div>
        <div style="flex: 1; position: relative">
          <label
            for="name"
            style="
              position: absolute;
              margin-top: 20px;
              margin-left: 8px;
              color: red;
            "
            >*</label
          >
          <input
            class="form"
            type="text"
            placeholder=" 手机/微信"
            required
            v-model="formState.phoneNumber"
          />
        </div>
        <div style="flex: 1; position: relative">
          <label
            for="name"
            style="
              position: absolute;
              margin-top: 20px;
              margin-left: 8px;
              color: red;
            "
            >*</label
          >
          <input
            class="form"
            type="text"
            placeholder=" 邮箱"
            required
            v-model="formState.email"
          />
        </div>
      </div>
      <input
        class="form"
        type="text"
        placeholder="公司名称"
        v-model="formState.projectName"
      />
      <textarea
        class="form"
        rows="3"
        placeholder="公司简介"
        v-model="formState.projectIntro"
      ></textarea>
      <input
        type="text"
        v-model="formState.hiddenField"
        style="display: none"
        autocomplete="off"
      />
    </div>
  </a-modal>
</template>

<script setup>
import { submitForm } from "@/api/api";
import content from "@/assets/center/content.json";
import { message } from "ant-design-vue";
import { ref } from "vue";

const open = ref(false);
const formState = ref({});

const showModal = () => {
  open.value = true;
};
const handleOk = () => {
  if (formState.value.hiddenField) {
    message.warn("检测到恶意提交");
    return;
  }
  if (!formState.value.name) {
    message.warn("姓名不能为空");
    return;
  }
  if (!formState.value.phoneNumber) {
    message.warn("手机/微信不能为空");
    return;
  }
  if (!formState.value.email) {
    message.warn("邮箱不能为空");
    return;
  }
  if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formState.value.email)) {
    message.warn("请输入正确的邮箱格式");
    return;
  }
  submitForm(formState.value).then((res) => {
    if (res.code === 0) {
      message.success("提交成功");
      open.value = false;
    } else {
      message.error(res.msg);
    }
  });
};
const handleCancel = () => {
  open.value = false;
};
</script>

<style scoped>
.container {
  display: flex;
  align-items: start;
  justify-content: center;
  width: 100%;
  gap: 40px;
  padding-bottom: 40px;
}
.container .card {
  width: 19%;
  color: #000000;
  padding: 20px;
  height: 100%;
  min-height: 640px;
  position: relative;
}

.container .card .img {
  width: 100%;
  margin-top: 20px;
}

.card .title {
  font-size: calc(100vw * 36 / 1920);
  font-weight: 700;
  height: 72px;
  display: flex;
  border-bottom: 1px solid black;
  white-space: pre-line;
  align-items: end;
  padding-bottom: 10px;
}
.card .item {
  display: flex;
  align-items: start;
  gap: 8px;
  font-size: 14px;
  margin-top: 16px;
}
.dot {
  background-color: #0dcaf0;
  margin-top: 8px;
  width: 6px;
  height: 6px;
  flex-basis: 6px;
  flex-shrink: 0;
}

.submit {
  position: absolute;
  bottom: 40px;
  margin-top: 20px;
  width: 120px;
  padding: 6px 24px;
  z-index: 9999;
  display: inline-block;
  font-size: 15px;
  line-height: normal;
  letter-spacing: 2px;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  color: #ffffff;
  background-color: #0dcaf0;
  border: none;
  z-index: 2;
  overflow: hidden;
  border-radius: 0;
}

.submit:before {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  display: block;
  content: "";
  width: 0;
  height: 0;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 12px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
}

.shape1 {
  background-color: #0dcaf0;
  width: 10px;
  height: 10px;
  position: absolute;
  z-index: 9;
  right: 10px;
  bottom: 10px;
}
.shape2 {
  background-color: #0dcaf0;
  width: 10px;
  height: 10px;
  position: absolute;
  z-index: 9;
  right: 0;
  bottom: 0;
}

@media screen and (max-width: 1000px) {
  .container .card {
    height: 580px;
  }
}

@media screen and (max-width: 720px) {
  .container {
    flex-direction: column;
    align-items: center;
  }
  .container .card {
    width: 80%;
  }
  .container .card .title {
    font-size: 24px;
  }
}

.colomn {
  display: flex;
  gap: 20px;
}

input[type="button"],
input[type="text"],
input[type="email"],
input[type="search"],
input[type="password"],
textarea,
input[type="submit"] {
  outline: none;
}

.form {
  width: calc(100% - 25px);
  min-height: 46px;
  border-radius: 0;
  display: block;
  padding: 6px 12px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: black;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid #dee2e6;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>
