<template>
  <div class="container">
    <h1 class="title">科创项目</h1>
    <div class="card-container">
      <div
        class="card"
        v-for="(item, index) in content.projects"
        :key="index"
        @click="openDetail(index)"
      >
        <img :src="require('@/assets/sciTechProj/' + item.img)" class="img" />
        <div class="bg"></div>
        <div class="index">0{{ index + 1 }}</div>
        <div class="proj-title">{{ item.title }}</div>
        <div class="readmore">了解详情 →</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import content from "@/assets/sciTechProj/content.json";
import { useRouter } from "vue-router";

const router = useRouter();
const openDetail = (id) => {
  router.push({ path: "/sciTechProj/detail", query: { id } });
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
  margin-bottom: 60px;
}

.container .title {
  color: #1e2c36;
  letter-spacing: 2px;
}

.container .card-container {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  overflow-x: scroll;
  padding: 0 80px;
}

.card {
  flex: 1;
  position: relative;
  height: 100%;
  cursor: pointer;
}

.card .img {
  width: 100%;
  border-radius: 5px;
}

.card .index {
  position: absolute;
  z-index: 100;
  color: white;
  font-size: 36px;
  font-weight: 700;
  top: 20px;
  left: 20px;
}

.card .proj-title {
  position: absolute;
  z-index: 100;
  color: white;
  font-size: 18px;
  font-weight: 500;
  bottom: 20px;
  left: 20px;
  transition: all 0.6s;
}

.card .readmore {
  position: absolute;
  z-index: 100;
  color: #0dcaf0;
  font-size: 14px;
  font-weight: 300;
  bottom: 0;
  left: 20px;
  transition: all 0.6s;
  visibility: hidden;
}

.card:hover .proj-title {
  margin-bottom: 20px;
}
.card:hover .readmore {
  margin-bottom: 20px;
  visibility: visible;
}

.bg {
  background-color: #1e2c36;
  opacity: 0.4;
  position: absolute;
  top: 0;
  height: 98%;
  width: 100%;
  border-radius: 5px;
  visibility: hidden;
}

.card:hover .bg {
  visibility: visible;
}

@media screen and (max-width: 720px) {
  .container .card-container {
    flex-direction: column;
  }
}
</style>
