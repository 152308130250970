<template>
  <div class="container">
    <div class="content">{{ content.definition }}</div>
    <button class="submit" type="submit" @click="() => router.push('/join')">
      <span>加入我们</span>
    </button>
  </div>
</template>
<script setup>
import content from "@/assets/innovation/content.json";
import { useRouter } from "vue-router";

const router = useRouter();
</script>

<style scoped>
.container {
  background-color: #264653;
  padding: 80px;
  display: flex;
  flex-direction: column;
}

.container .content {
  color: white;
  line-height: 40px;
  font-size: 20px;
}

.submit {
  margin-left: auto;
  margin-top: 20px;
  width: 160px;
  padding: 10px 24px;
  position: relative;
  z-index: 9999;
  display: inline-block;
  font-size: 18px;
  line-height: normal;
  letter-spacing: 4px;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  color: #ffffff;
  background-color: #0dcaf0;
  border: none;
  z-index: 2;
  overflow: hidden;
  border-radius: 0;
}

.submit:before {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  display: block;
  content: "";
  width: 0;
  height: 0;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 12px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
}
</style>