import axios from "axios";

const developBaseURL = "/api";

//创建axios实例
const service = axios.create({
  baseURL: developBaseURL,
  timeout: 20000,
  headers: {
    "Content-type": "application/json;charset=utf-8",
  },
});

//请求拦截
service.interceptors.request.use(
  (config) => {
    config.headers = config.headers || {};
    if (localStorage.getItem("token")) {
      config.headers.Authorization = localStorage.getItem("token") || "";
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//响应拦截
service.interceptors.response.use(
  (res) => {
    if (res.status !== 200) {
      return Promise.reject(res);
    } else {
      return Promise.resolve(res.data);
    }
  },
  (err) => {
    return Promise.reject(err);
  }
);

export default service;
