<template>
  <header :class="['header', { fixed: isFixed }]">
    <nav class="navbar">
      <span
        v-for="item in navbarItem"
        :key="item.path"
        class="button"
        @click="handleClick(item.path)"
        :style="{
          color:
            router.currentRoute.value.fullPath === item.path
              ? '#0dcaf0'
              : router.currentRoute.value.fullPath === '/' && !isFixed
              ? 'white'
              : 'black',
        }"
      >
        {{ item.name }}
      </span>
    </nav>
    <div class="navbar-shrink">
      <a-dropdown placement="bottomRight"
        ><BarsOutlined style="color: #0dcaf0; font-size: 36px" />
        <template #overlay>
          <a-menu>
            <a-menu-item v-for="item in navbarItem" :key="item.path">
              <div
                @click="handleClick(item.path)"
                style="min-width: 140px"
                class="navbar-item"
                :style="{
                  color:
                    router.currentRoute.value.fullPath === item.path
                      ? '#0dcaf0'
                      : 'black',
                }"
              >
                {{ item.name }}
              </div>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </div>
  </header>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import { BarsOutlined } from "@ant-design/icons-vue";

const navbarItem = ref([
  {
    path: "/",
    name: "关于我们",
    active: true,
  },
  {
    path: "/fourCenter",
    name: "四大中心",
    active: false,
  },
  {
    path: "/sciTechProj",
    name: "科创项目",
    active: false,
  },
  {
    path: "/innovation",
    name: "大企业创新",
    active: false,
  },
  {
    path: "/sciTechEco",
    name: "科创生态",
    active: false,
  },
  {
    path: "/cowork",
    name: "联合办公",
    active: false,
  },
  {
    path: "/news",
    name: "最新动态",
    active: false,
  },
  {
    path: "/join",
    name: "加入我们",
    active: false,
  },
]);
const isFixed = ref(false);
const router = useRouter();

const handleScroll = () => {
  if (window.scrollY > 48) {
    isFixed.value = true;
  } else {
    isFixed.value = false;
  }
};

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});

onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll);
});

const handleClick = (key) => {
  router.push(key);
};
</script>

<style scoped>
.navbar {
  text-align: right;
  padding: 0;
  flex-wrap: nowrap;
  justify-content: flex-end;
  display: flex;
  gap: 10px;
}

.header {
  transition: top 0.5s;
  height: 40px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  flex: 0 0 auto;
  padding: 10px;
}

.header.fixed {
  position: fixed;
  background-color: white;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.navbar .button {
  color: white;
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 1.5;
}

.navbar-shrink {
  display: none;
  text-align: right;
  padding: 0;
}

.navbar-item {
  color: #264653;
  font-weight: 700;
}

@media screen and (max-width: 720px) {
  .navbar {
    display: none;
  }
  .navbar-shrink {
    display: block;
  }
}
</style>
