import Home from "@/views/Home.vue";
import FourCenter from "@/views/FourCenter.vue";
import SciTechProject from "@/views/SciTechProject.vue";
import SciTechProjDetail from "@/components/sciTechProj/Detail.vue";
import SciTechEco from "@/views/SciTechEco.vue";
import News from "@/views/News.vue";
import Cowork from "@/views/Cowork.vue";
import CoworkDetail from "@/components/cowork/Detail.vue";
import JoinUs from "@/views/JoinUs.vue";
import CompanyInnovation from "@/views/CompanyInnovation.vue";
import NotFound from "@/views/NotFound.vue";
import Privacy from "@/views/Privacy.vue";
import AdminContact from "@/views/AdminContact.vue";
import AdminLogin from "@/views/AdminLogin.vue";
import { createRouter, createWebHashHistory } from "vue-router";
import AdminArticle from "@/views/AdminArticle.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: Privacy,
  },
  {
    path: "/fourCenter",
    name: "FourCenter",
    component: FourCenter,
  },
  {
    path: "/sciTechProj",
    redirect: "/sciTechProj",
    children: [
      {
        path: "",
        name: "SciTechProj",
        component: SciTechProject,
      },
      {
        path: "detail",
        name: "SciTechProjDetail",
        component: SciTechProjDetail,
      },
    ],
  },
  {
    path: "/sciTechEco",
    name: "SciTechEco",
    component: SciTechEco,
  },
  {
    path: "/news",
    name: "News",
    component: News,
  },
  {
    path: "/join",
    name: "JoinUs",
    component: JoinUs,
  },
  {
    path: "/cowork",
    name: "Cowork",
    component: Cowork,
  },
  {
    path: "/cowork",
    redirect: "/cowork",
    children: [
      {
        path: "",
        name: "Cowork",
        component: Cowork,
      },
      {
        path: "detail",
        name: "CoworkDetail",
        component: CoworkDetail,
      },
    ],
  },
  {
    path: "/innovation",
    name: "CompanyInnovation",
    component: CompanyInnovation,
  },
  // {
  //   path: "/admin",
  //   name: "Admin",
  //   component: Admin,
  // },
  {
    path: "/admin",
    redirect: "/admin/contact",
    children: [
      {
        path: "contact",
        name: "ContactManage",
        component: AdminContact,
      },
      {
        path: "article",
        name: "ArticleManage",
        component: AdminArticle,
      },
    ],
  },
  {
    path: "/adminLogin",
    name: "AdminLogin",
    component: AdminLogin,
  },
  {
    path: "/:catchAll(.*)",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  // history: createWebHistory(),
  routes,
});

export default router;
