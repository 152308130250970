<template>
  <div class="container">
    <div class="action">
      <a-button @click="showModal"> 手动上传 </a-button>
    </div>
    <a-table
      :columns="columns"
      :data-source="formList"
      :scroll="{ x: 'max-content', y: '70vh' }"
      :rowKey="(record) => record.id"
      :pagination="{
        defaultPageSize: 20,
        total: total,
        showTotal: (total) => `共 ${total} 条记录`,
        onChange: (page) => change(page),
      }"
      ><template #bodyCell="{ column, text, record }">
        <template v-if="column.key === 'createTime'">{{
          timestampToDate(record.createTime)
        }}</template>
        <template v-if="column.key === 'operation'">
          <a @click="display(record.id)">{{
            record.display ? "取消展示" : "展示"
          }}</a>
        </template>
      </template>
    </a-table>
  </div>
  <a-modal v-model:open="open" title="上传公众号文章" :width="800">
    <template #footer>
      <a-button key="back" @click="handleCancel">取消</a-button>
      <a-button key="submit" type="primary" @click="handleOk">提交</a-button>
    </template>
    <a-form :model="formState" name="basic" autocomplete="off">
      <!-- <a-form-item label="图文消息id" name="articleId">
        <a-input v-model:value="formState.articleId" />
      </a-form-item> -->
      <a-form-item label="公众号名称" name="officialAccountName">
        <a-input v-model:value="formState.officialAccountName" />
      </a-form-item>
      <a-form-item label="标题" name="title">
        <a-input v-model:value="formState.title" />
      </a-form-item>
      <a-form-item label="作者" name="author">
        <a-input v-model:value="formState.author" />
      </a-form-item>
      <a-form-item label="封面url" name="thumbUrl">
        <a-input v-model:value="formState.thumbUrl" />
      </a-form-item>
      <a-form-item label="文章链接" name="url">
        <a-input v-model:value="formState.url" />
      </a-form-item>
      <a-form-item label="发布时间" name="createTime">
        <a-date-picker v-model:value="formState.createTime" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script setup>
import { getArticles, selectArticle, uploadArticle } from "@/api/api";
import { message } from "ant-design-vue";
import { onMounted, reactive, ref } from "vue";

const formList = ref([]);
const total = ref(0);
const pageNo = ref(1);

const timestampToDate = (timestamp) => {
  const date = new Date(timestamp * 1); // 将时间戳转为日期对象
  const year = date.getFullYear(); // 获取年份
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 获取月份，并补齐两位
  const day = date.getDate().toString().padStart(2, "0"); // 获取日期，并补齐两位

  return `${year}-${month}-${day}`;
};

onMounted(() => {
  getArticles(pageNo.value).then((res) => {
    formList.value = res.data.contentList;
    total.value = res.data.totalCount;
  });
});
const open = ref(false);
const formState = reactive({});
const showModal = () => {
  open.value = true;
};

const handleCancel = () => {
  open.value = false;
};
const handleOk = () => {
  const form = {
    articleId: "",
    officialAccountName: formState.officialAccountName,
    title: formState.title,
    author: formState.author,
    digest: "",
    thumbUrl: formState.thumbUrl,
    content: "",
    contentSourceUrl: "",
    url: formState.url,
    additionalInfo: null,
    createTime: formState.createTime.unix() * 1000,
  };
  // console.log(form)
  uploadArticle(form).then((res) => {
    if (res.code === 0) {
      message.success("上传成功");
      getArticles(pageNo.value).then((r) => {
        formList.value = r.data.contentList;
        total.value = r.data.totalCount;
      });
      open.value = false;
    } else message.error(res.msg);
  });
};

const display = (id) => {
  selectArticle(id).then((res) => {
    if (res.code === 0) {
      message.success("操作成功");
      getArticles(pageNo.value).then((r) => {
        formList.value = r.data.contentList;
      });
    } else message.error(res.msg);
  });
};
const columns = [
  {
    title: "公众号名称",
    dataIndex: "officialAccountName",
    key: "officialAccountName",
    align: "center",
  },
  {
    title: "标题",
    dataIndex: "title",
    key: "title",
    align: "center",
  },
  {
    title: "作者",
    dataIndex: "author",
    key: "author",
    align: "center",
  },
  {
    title: "发布时间",
    dataIndex: "createTime",
    key: "createTime",
    align: "center",
  },
  {
    title: "封面图片链接",
    dataIndex: "thumbUrl",
    key: "thumbUrl",
    align: "center",
  },
  {
    title: "图文消息链接",
    dataIndex: "url",
    key: "url",
    align: "center",
  },
  {
    title: "图文消息id",
    dataIndex: "articleId",
    key: "articleId",
    align: "center",
  },
  {
    title: "操作",
    dataIndex: "operation",
    key: "operation",
    align: "center",
    fixed: "right",
    width: 100,
  },
];
</script>
<style scoped>
.container {
  width: 100%;
  height: 100vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.container .action {
  display: flex;
  justify-content: space-between;
}

input[type="button"],
input[type="text"],
input[type="email"],
input[type="search"],
input[type="password"],
textarea,
input[type="submit"] {
  outline: none;
}

.form {
  width: calc(100% - 25px);
  min-height: 28px;
  border-radius: 0;
  display: block;
  padding: 6px 12px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: black;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid #dee2e6;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>
