<template>
  <div class="container">
    <img
      src="@/assets/sciTechEco/ecoIntro.png"
      class="img"
      @click="showPreview"
    />
    <div v-if="isPreviewVisible" class="preview-modal" @click="hidePreview">
      <img
        src="@/assets/sciTechEco/ecoIntro.png"
        class="preview-image"
        @click.stop
      />
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";

const isPreviewVisible = ref(false);
const showPreview = () => {
  isPreviewVisible.value = true;
};
const hidePreview = () => {
  isPreviewVisible.value = false;
};
</script>
<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
}
.img {
  width: 30%;
}

.preview-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.preview-image {
  max-width: 80%;
  max-height: 80%;
  object-fit: contain;
}
</style>
