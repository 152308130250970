<template>
  <div class="container">
    <div class="content">
      <div class="right" @click="showPreview">
        <img
          :src="require('@/assets/sciTechEco/' + content.ecoIntro.img)"
          class="img"
        />
        <span>点击查看大图</span>
      </div>
      <div>
        <h1>{{ content.ecoIntro.title }}</h1>
        <p>{{ content.ecoIntro.eco }}</p>
        <p v-for="(item, index) in content.ecoIntro.platforms" :key="index">
          <!-- <span>{{ index + 1 }}. </span> -->
          {{ item }}
        </p>
        <p>{{ content.ecoIntro.summary }}</p>
      </div>
    </div>
  </div>
  <div v-if="isPreviewVisible" class="preview-modal" @click="hidePreview">
    <img
      src="@/assets/sciTechEco/ecoIntro.png"
      class="preview-image"
      @click.stop
    />
  </div>
</template>
<script setup>
import content from "@/assets/sciTechEco/content.json";
import { ref } from "vue";

const isPreviewVisible = ref(false);
const showPreview = () => {
  isPreviewVisible.value = true;
};
const hidePreview = () => {
  isPreviewVisible.value = false;
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #1e2c36;
  margin-bottom: 40px;
}

.content {
  display: flex;
  align-items: start;
  gap: 100px;
  padding: 0 160px;
}

.content .right {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  color: rgb(118, 118, 118);
  gap: 10px;
  font-size: 12px;
  cursor: pointer;
}
.content .right .img {
  width: 100%;
  object-fit: contain;
}

.content p {
  line-height: 28px;
  letter-spacing: 1px;
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 20px;
  font-family: "Syne", sans-serif;
}

.container p:last-child {
  border-bottom: none;
}

@media screen and (max-width: 1000px) {
  .content {
    padding: 0 120px;
  }
}
@media screen and (max-width: 720px) {
  .content {
    flex-direction: column;
    padding: 0 80px;
  }
  .content .right {
    width: 100%;
  }
}

.preview-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(38, 70, 83, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.preview-image {
  max-width: 80%;
  max-height: 80%;
  object-fit: contain;
}
</style>
