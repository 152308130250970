import service from "@/api/index";

/**
 * 获取最新动态
 */
export function getLastestNews() {
  return service({
    url: "/api/v1/official-account/latest",
    method: "GET",
  });
}

/**
 * 获取加入生态表单
 */
export function getFormList(pageNo, params) {
  return service({
    url: `/api/v1/contact/biz/page/${pageNo}`,
    method: "GET",
    params,
  });
}

/**
 * 登录
 */
export function login(data) {
  return service({
    url: "/api/v1/biz/user/login",
    method: "POST",
    data,
  });
}

/**
 * 已读
 */
export function read(data) {
  return service({
    url: "/api/v1/contact/biz/read",
    method: "POST",
    data,
  });
}

/**
 * 导出
 */
export function exportFile(data) {
  return service({
    url: "/api/v1/contact/biz/export",
    method: "POST",
    data,
    responseType: "blob",
  });
}

/**
 * 提交表单
 */
export function submitForm(data) {
  return service({
    url: "/api/v1/contact",
    method: "POST",
    data,
  });
}

/**
 * 获取一页公众号文章
 */
export function getArticles(pageNo) {
  return service({
    url: `/api/v1/official-account/biz/page/${pageNo}`,
    method: "GET",
  });
}

/**
 * 上传公众号文章
 */
export function uploadArticle(data) {
  return service({
    url: "/api/v1/official-account/biz/upload",
    method: "POST",
    data,
  });
}

/**
 * 选择展示的文章
 */
export function selectArticle(id) {
  return service({
    url: `/api/v1/official-account/biz/display/${id}`,
    method: "POST",
  });
}