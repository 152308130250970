<template>
  <layout v-if="!router.currentRoute.value.fullPath.startsWith('/admin')" />
  <router-view
    v-if="!router.currentRoute.value.fullPath.startsWith('/admin/')"
  />
  <a-layout
    has-sider
    v-if="router.currentRoute.value.fullPath.startsWith('/admin/')"
  >
    <a-layout-sider
      width="200"
      class="sider"
      v-model:collapsed="state.collapsed"
    >
      <div class="title-container">
        <img src="@/assets/avatar-gray.png" class="avatar" />
      </div>
      <a-menu
        class="menu"
        v-model:selectedKeys="selectedKeys"
        v-model:openKeys="openKeys"
        mode="inline"
        :items="items"
        @click="handleClick"
      ></a-menu>
      <div @click="logout" class="logout"><LogoutOutlined /> 退出登录</div>
    </a-layout-sider>
    <a-layout-content>
      <router-view />
    </a-layout-content>
  </a-layout>
</template>

<script setup>
import Layout from "@/components/Layout.vue";
import { useRouter } from "vue-router";
import { LogoutOutlined } from "@ant-design/icons-vue";
import { reactive, ref, watch } from "vue";
import { read } from "./api/api";

const router = useRouter();

const handleClick = (e) => {
  const unreadList = JSON.parse(localStorage.getItem("unread"));
  read({ idList: unreadList }).then((res) => {
    if (res.code === 0) {
      router.push(e.key);
    }
  });
};
const state = reactive({
  collapsed: false,
});
const selectedKeys = ref([router.currentRoute.value.fullPath]);
const openKeys = ref([router.currentRoute.value.fullPath]);
const items = [
  {
    key: "/admin/contact",
    label: "加入生态",
    title: "加入生态",
  },
  {
    key: "/admin/article",
    label: "文章管理",
    title: "文章管理",
  },
];

const logout = () => {
  const unreadList = JSON.parse(localStorage.getItem("unread"));
  read({ idList: unreadList }).then((res) => {
    if (res.code === 0) {
      router.push("/adminLogin");
      localStorage.removeItem("token");
    }
  });
};

watch(
  () => router.currentRoute.value.path,
  (path) => {
    router.getRoutes().map((item) => {
      if (path === item.path) {
        selectedKeys.value = [item.path];
        openKeys.value = [item.path];
      }
    });
  },
  {
    deep: false,
    immediate: false,
  }
);
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Syne:wght@400;500;600;700;800&display=swap");
body {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: sans-serif;
}
</style>

<style scoped>
.sider {
  min-height: 100vh;
  max-height: 100vh;
}
.avatar {
  width: 40%;
}
.title-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 30vh;
  gap: 8px;
}

.logout {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-weight: 700;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: center;
  cursor: pointer;
}
</style>

<style>
.ant-menu-light .ant-menu-item-selected {
  background-color: #264653;
  color: white;
  border-radius: 0;
}

.ant-layout-sider-children {
  background-color: #1e2c36;
}
.ant-menu-light {
  background-color: #1e2c36;
}
.ant-menu-item {
  color: white;
  text-align: center;
  padding-left: 16px !important;
}

.ant-menu-item-active {
  color: white !important;
  border-radius: 0 !important;
}
</style>
