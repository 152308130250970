<template>
  <div class="container">
    <div class="login-container">
      <h1 style="color: white">后台管理登录</h1>
      <input
        class="form"
        type="text"
        placeholder="用户名"
        v-model="formState.username"
      />
      <input
        class="form"
        type="text"
        placeholder="密码"
        v-model="formState.password"
      />
      <button class="submit" type="submit" @click="submit">
        <span>登&nbsp;&nbsp;&nbsp;&nbsp;录</span>
      </button>
    </div>
  </div>
</template>

<script setup>
import { login } from "@/api/api";
import { message } from "ant-design-vue";
import { reactive } from "vue";
import { useRouter } from "vue-router";
import md5 from "js-md5";

const router = useRouter();

const submit = () => {
  login({
    account: formState.username,
    password: md5(formState.password),
  }).then((res) => {
    if (res.code === 0) {
      localStorage.setItem("token", res.data);
      router.push("/admin");
    } else {
      message.error(res.msg);
    }
  });
};
const formState = reactive({
  username: "",
  password: "",
});
</script>

<style scoped>
.container {
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
}
.login-container {
  background-color: #264653;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  width: 40%;
  padding: 20px 80px;
}

input[type="button"],
input[type="text"],
input[type="email"],
input[type="search"],
input[type="password"],
textarea,
input[type="submit"] {
  outline: none;
}

.form {
  flex: 1;
  min-height: 40px;
  border-radius: 0;
  display: block;
  width: 100%;
  padding: 6px 12px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: black;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid #dee2e6;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.submit {
  width: 50%;
  padding: 12px 24px !important;
  position: relative;
  z-index: 9999;
  display: inline-block;
  font-size: 15px !important;
  line-height: normal;
  letter-spacing: 2px;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  color: #ffffff;
  background-color: #0dcaf0;
  border: none;
  z-index: 2;
  overflow: hidden;
  border-radius: 0;
}

.submit:before {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  display: block;
  content: "";
  width: 0;
  height: 0;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 13px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
}
</style>
