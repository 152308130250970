<template>
  <div class="container">
    <div class="action">
      <a-input-search
        v-model:value="filter"
        placeholder="输入关键词进行搜索"
        style="width: 200px"
        @search="onSearch"
      />
      <a-dropdown>
        <template #overlay>
          <a-menu @click="download">
            <a-menu-item key="EXCEL">EXCEL</a-menu-item>
            <a-menu-item key="WORD">WORD</a-menu-item>
            <a-menu-item key="TXT">TXT</a-menu-item>
          </a-menu>
        </template>
        <a-button>
          导出
          <DownOutlined />
        </a-button>
      </a-dropdown>
    </div>
    <a-table
      :columns="columns"
      :data-source="formList"
      :scroll="{ x: 'max-content', y: '70vh' }"
      :row-selection="rowSelection"
      :rowKey="(record) => record.id"
      :pagination="{
        defaultPageSize: 20,
        total: total,
        showTotal: (total) => `共 ${total} 条记录`,
        onChange: (page) => change(page),
      }"
    >
      <template #bodyCell="{ column, text, record }">
        <strong v-if="!record.read">{{ text }}</strong>
        <span v-else>{{ text }}</span>
      </template>
    </a-table>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { DownOutlined } from "@ant-design/icons-vue";
import { exportFile, getFormList } from "@/api/api";
import { message } from "ant-design-vue";

const unreadList = ref([]);

onMounted(() => {
  getFormList(pageNo.value, { filter: filter.value }).then((res) => {
    formList.value = res.data.contentList;
    total.value = res.data.totalCount;
    unreadList.value = res.data.contentList
      .filter((item) => !item.read)
      .map((item) => item.id);
    localStorage.setItem("unread", JSON.stringify(unreadList.value));
  });
});
const formList = ref([]);
const total = ref(0);
const pageNo = ref(1);
const filter = ref("");
const change = (page) => {
  pageNo.value = page;
  getFormList(page, { filter: filter.value }).then((res) => {
    formList.value = res.data.contentList;
    const unread = res.data.contentList
      .filter((item) => !item.read)
      .map((item) => item.id);
    unreadList.value = new Set(unreadList.value.concat(unread));
    localStorage.setItem("unread", JSON.stringify(unreadList.value));
  });
};

const onSearch = () => {
  pageNo.value = 1;
  getFormList(1, { filter: filter.value }).then((res) => {
    formList.value = res.data.contentList;
    total.value = res.data.totalCount;
    const unread = res.data.contentList
      .filter((item) => !item.read)
      .map((item) => item.id);
    unreadList.value = new Set(unreadList.value.concat(unread));
    localStorage.setItem("unread", JSON.stringify(unreadList.value))
  });
};

const exportList = ref([]);

const rowSelection = {
  onChange: (selectedRowKeys) => {
    exportList.value = selectedRowKeys;
  },
};

const blobType = {
  EXCEL: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  WORD: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  TXT: "multipart/form-data",
};
const download = (e) => {
  if (exportList.value.length === 0) {
    message.warn("请先选择需要导出的数据");
    return;
  }
  exportFile({
    type: e.key,
    idList: exportList.value,
  }).then((res) => {
    let url = URL.createObjectURL(
      new Blob([res], {
        type: blobType[e.key],
      })
    );
    let link = document.createElement("a");
    link.style.display = "none";
    link.href = url;
    if (e.key === "TXT") {
      link.download = url.split("/")[3] + ".txt";
    }
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(url);
  });
};

const columns = [
  {
    title: "姓名",
    dataIndex: "name",
    key: "name",
    align: "center",
  },
  {
    title: "手机/微信",
    dataIndex: "phoneNumber",
    key: "phoneNumber",
    align: "center",
  },
  {
    title: "邮箱",
    dataIndex: "email",
    key: "email",
    align: "center",
  },
  {
    title: "公司名称",
    dataIndex: "projectName",
    key: "projectName",
    align: "center",
  },
  {
    title: "公司简介",
    dataIndex: "projectIntro",
    key: "projectIntro",
    align: "center",
  },
];
</script>

<style scoped>
.container {
  width: 100%;
  height: 100vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.container .action {
  display: flex;
  justify-content: space-between;
}
</style>
