<template>
  <div class="detail-container">
    <div class="grey-space">
      <img
        v-if="detail.detailImg"
        :src="require('@/assets/cowork/' + detail.detailImg)"
        class="img"
      />
      <div class="text-container">
        <div class="subtitle">{{ detail.subTitle }}</div>
        <p>{{ detail.intro }}</p>
        <p>{{ detail.address }}</p>
        <p>{{ detail.phone }}</p>
      </div>
    </div>
    <div style="display: flex; justify-content: space-around; margin-top: 60px">
      <div
        v-for="(item, index) in detail.detail"
        :key="index"
        style="
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
        "
      >
        <img
          v-if="detail.icon && detail.icon[index]"
          :src="require('@/assets/cowork/' + detail.icon[index])"
          class="icon"
        />
        <div class="icon-text">{{ item }}</div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import content from "@/assets/cowork/content.json";
const route = useRoute();
const detail = ref({
  title: "",
  intro: "",
  detail: [],
});

onMounted(() => {
  const id = route.query.id;
  detail.value = content.projects[id];
});
</script>

<style scoped>
.detail-container {
  margin-top: 80px;
  padding: 0 80px;
}
.detail-container .grey-space {
  display: flex;
  height: 100%;
}

.detail-container .img {
  width: 60%;
  height: fit-content;
}

.detail-container .text-container {
  background-color: #f0f0f0;
  padding: 20px 40px;
}

.detail-container .text-container .subtitle {
  color: #575a7b;
  font-size: 24px;
  font-weight: 1000;
  border-bottom: 1px solid #1e2c36;
  padding-bottom: 28px;
  margin-bottom: 32px;
}
.detail-container .text-container p {
  color: #1e2c36;
}
.detail-container .icon {
  width: 20%;
}
.detail-container .icon-text {
  margin-top: 16px;
  color: #1e2c36;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 2px;
}

@media screen and (max-width: 1000px) {
  .detail-container .grey-space {
    flex-direction: column;
    align-items: center
  }
  .detail-container .img {
    width: 100%;
  }
}
</style>
