<template>
  <div
    class="container"
    :style="{ backgroundColor: props.type === 'white' ? '#fff' : '#1e2c36' }"
  >
    <h1
      class="title"
      :style="{ color: props.type === 'white' ? '#1e2c36' : '#fff' }"
    >
      最新动态
    </h1>
    <div class="card-container">
      <article
        v-for="item in newsList"
        v-show="item.id"
        :key="item.id"
        class="card"
        @click="openUrl(item.url)"
      >
        <div class="img-container">
          <img :src="item.thumbUrl" class="img" />
        </div>
        <div class="date">
          <div class="time">
            <img src="@/assets/home/date.png" class="icon" />{{
              timestampToDate(item.createTime * 1)
            }}
          </div>
        </div>
        <div class="content">
          <div class="author">
            <img src="@/assets/home/user.png" class="icon" />{{ item.author }}
          </div>
          <div class="subtitle">{{ item.title }}</div>
          <div class="readmore">详情</div>
        </div>
      </article>
    </div>
  </div>
</template>

<script setup>
import { getLastestNews } from "@/api/api";
import { onMounted, ref, defineProps } from "vue";

const timestampToDate = (timestamp) => {
  const date = new Date(timestamp); // 将时间戳转为日期对象
  const year = date.getFullYear(); // 获取年份
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 获取月份，并补齐两位
  const day = date.getDate().toString().padStart(2, "0"); // 获取日期，并补齐两位

  return `${year}-${month}-${day}`;
};

const props = defineProps(["type"]);
const newsList = ref([]);

onMounted(() => {
  getLastestNews().then((res) => {
    newsList.value = res.data;
  });
});

const openUrl = (url) => {
  window.open(url, "_blank");
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 100px;
  background-color: #1e2c36;
  padding-bottom: 60px;
}
.container .title {
  color: #fff;
  letter-spacing: 4px;
  margin-bottom: 40px;
}

.container .card {
  background-color: rgb(248, 249, 250);
  flex: 1;
  position: relative;
  cursor: pointer;
}

.container .card-container {
  display: flex;
  gap: 40px;
  width: 100%;
}

.container .card .img-container {
  padding-bottom: 48%;
  width: 100%;
  position: relative;
}

.container .card .img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.container .card .date {
  position: absolute;
  right: -50px;
  top: 72px;
  width: 120px;
  height: 20px;
  transform: translateY(-50%);
}

.date .time {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
  background-color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 4px;
  gap: 8px;
  color: #0dcaf0;
  font-family: "Syne", sans-serif;
  font-weight: 700;
  font-size: 14px;
}
.date .time .icon {
  width: 14px;
}

.content {
  padding: 12px;
}
.author .icon {
  width: 16px;
}

.author {
  font-weight: 300;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
}

.author .subtitle {
  font-weight: 700;
  margin-bottom: 8px;
}

.readmore {
  color: #575a7b;
  font-size: 14px;
  margin-top: 8px;
  margin-bottom: 8px;
}

@media screen and (max-width: 720px) {
  .container .card-container {
    flex-direction: column;
  }
}
</style>
