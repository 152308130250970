<template>
  <!-- 详情 -->
  <div class="detail-container">
    <!-- <div class="shape"></div> -->
    <!-- <div class="shape2"></div> -->
    <img
      v-if="detail.detailImg"
      :src="require('@/assets/sciTechProj/' + detail.detailImg)"
      class="img"
    />
    <div>
      <h1>{{ detail.title }}</h1>
      <p>{{ detail.detail }}</p>
      <!-- <p style="border-bottom: 1px solid #dee2e6; padding-bottom: 20px">
        联系方式：{{ detail.phone }}
      </p> -->
      <!-- <div class="video">
        <img src="@/assets/sciTechProj/play.png" class="icon" />
        <span>Watch Video</span>
      </div> -->
    </div>
  </div>
  <!-- 底部导航 - 1 -- 加入我们(加入生态) -->
  <JoinEcology />
  <!-- 底部导航 - 2 -- 加入团队 / 联系我们 -->
  <Contact />
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import JoinEcology from "@/components/bottom/JoinEcology.vue";
import Contact from "@/components/bottom/Contact.vue";
import content from "@/assets/sciTechProj/content.json";
const route = useRoute();
const detail = ref({
  title: "",
  detail: "",
  phone: "",
});

onMounted(() => {
  const id = route.query.id;
  detail.value = content.projects[id];
});
</script>

<style scoped>
.detail-container {
  margin-top: 80px;
  display: flex;
  justify-content: space-between;
  gap: 40px;
  padding: 0 80px;
  align-items: start;
  margin-bottom: 40px;
  position: relative;
}

.detail-container .shape {
  width: 100px;
  height: 100px;
  background-color: #0dcaf0;
  position: absolute;
  left: -20px;
  /* bottom: 40px; */
  z-index: -1;
}
.detail-container .shape2 {
  width: 100px;
  height: 40px;
  background-color: white;
  position: absolute;
  left: 80px;
  bottom: 0;
}

.detail-container .img {
  width: 40%;
  margin-top: 28px;
}

.detail-container p {
  margin: 0 0 20px;
  padding: 0;
  box-sizing: border-box;
  font-size: 16px;
  letter-spacing: 0.5px;
  color: #575a7b;
  font-weight: 400;
  line-height: 1.7;
  /* margin-bottom: 20px; */
}

.detail-container h2 {
  color: #0dcaf0;
}

.video .icon {
  width: 50px;
}

.video {
  display: flex;
  align-items: center;
  gap: 20px;
  color: #575a7b;
  font-family: "Syne", sans-serif;
  cursor: pointer;
}

@media screen and (max-width: 1000px) {
  .detail-container {
    flex-direction: column;
    align-items: center
  }
  .detail-container .img {
    width: 100%;
  }
}
</style>
