<template>
  <div class="container">
    <div class="right">
      <div class="card border">
        <div class="title">加入团队</div>
        <div class="content"><PhoneOutlined />{{ content.join.phone }}</div>
        <div class="content"><MailOutlined />{{ content.join.mail }}</div>
        <div
          class="title"
          style="margin-top: 20px; text-decoration: underline; cursor: pointer"
          @click="router.push('/privacy')"
        >
          免责及隐私声明
        </div>
        <div class="content" style="font-size: 12px;">
          {{ content.copyright[0] }} {{ content.copyright[1] }}
        </div>
      </div>
      <div class="card border">
        <div class="title">联系我们</div>
        <div class="content"><PhoneOutlined />{{ content.contact.phone }}</div>
        <div class="content"><MailOutlined />{{ content.contact.mail }}</div>
        <div class="content" style="align-items: start">
          <EnvironmentOutlined style="margin-top: 4px" />
          <div style="display: flex; flex-direction: column; gap: 2px">
            <div v-for="item in content.contact.location" :key="item">
              {{ item }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="qrcode">
      <div class="title">扫码关注</div>
      <img src="@/assets/bottom/qrcode.png" />
    </div>
    <!-- <div class="shape-1"></div>
    <div class="shape-2"></div> -->
  </div>
</template>

<script setup>
import {
  PhoneOutlined,
  MailOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons-vue";
import content from "@/assets/bottom/content.json";
import { useRouter } from "vue-router";

const router = useRouter();
</script>

<style scoped>
.container {
  background-color: #1e2c36;
  position: relative;
  display: flex;
  padding: 20px 80px;
  gap: 20px;
}

.card {
  flex: 1;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.card .content {
  color: white;
  display: flex;
  gap: 8px;
  align-items: center;
  font-weight: 300;
  font-size: 14px;
}

.card .title {
  color: white;
  font-weight: 700;
  letter-spacing: 2px;
  margin-bottom: 10px;
}

.card.border {
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.shape-1 {
  background-color: #fff;
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 9;
}

.shape-2 {
  background-color: #0dcaf0;
  width: 30px;
  height: 30px;
  position: absolute;
  z-index: 9;
  right: 30px;
  bottom: 30px;
}

.qrcode img {
  width: 100px;
}
.qrcode .title {
  color: white;
  font-weight: 700;
  letter-spacing: 2px;
  margin-bottom: 20px;
}

.container .right {
  display: flex;
  width: 80%;
}

@media screen and (max-width: 720px) {
  .container {
    flex-direction: column;
    padding: 20px 0;
  }
  .container .right {
    flex-direction: column;
    width: auto;
  }
  .card.border {
    border: none;
  }
  .qrcode {
    padding: 0 20px;
  }
}
</style>
