<template>
  <div class="container">
    <h1 class="title">四大中心</h1>
    <div class="card-container">
      <div
        class="card"
        v-for="(item, index) in content.projects"
        :key="item.title"
        @click="openDetail(index)"
      >
        <div class="card-content">
          <div class="subtitle">{{ item.title }}</div>
          <img :src="require('@/assets/home/' + item.img)" class="img" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import content from "@/assets/cowork/content.json";
import { useRouter } from "vue-router";

const router = useRouter();
const openDetail = (id) => {
  if (id < 3) router.push({ path: "/cowork/detail", query: { id } });
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px 80px;
}

.container .title {
  color: #264653;
  letter-spacing: 4px;
  margin-bottom: 40px;
}

.container .card {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: start;
}

.container .card-container {
  display: flex;
  gap: 40px;
}

.container .card-content {
  background-color: #fff;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);
  /* width: 320px; */
  align-items: center;
  display: flex;
  flex-direction: column;
}

.card-content .img {
  width: 100%;
}

.card-content .subtitle {
  color: #1e2c36;
  font-size: 18px;
  height: 80px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  white-space: pre-line;
  text-align: start;
  width: calc(100% - 40px);
}

@media screen and (max-width: 720px) {
  .container .card-container {
    flex-direction: column;
  }
}
</style>
